import React, { useEffect, useState, useContext } from "react";
import { useHistory } from "react-router-dom";
import {
  List,
  ListItem,
  ListItemText,
  Divider,
  LinearProgress,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  Popover,
  Box,
  IconButton,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import PopupState, { bindTrigger, bindPopover } from "material-ui-popup-state";
import Alert from "@material-ui/lab/Alert";
import DeleteIcon from "@material-ui/icons/Delete";
import NotesIcon from "@material-ui/icons/Notes";
import EditIcon from "@material-ui/icons/Edit";

import AppContext from "../AppContext";
import {
  formattedDate,
  getCurrentMonth,
  getCurrentYear,
  getYearOptions,
  getMonthOptions,
} from "../utilities/date";
import HandoverFilter from "../HandoverFilter/HandoverFilter";
import HandoverService from "../services/HandoverService";

const currentMonth = getCurrentMonth();
const currentYear = getCurrentYear();

const monthOptions = getMonthOptions({ addAllOption: true });
const availableYears = getYearOptions({ addAllOption: true });

const useStyles = makeStyles((theme) => ({
  textRight: {
    textAlign: "right",
  },
  inline: {
    display: "inline",
  },
  divider: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
}));

function HandoverList(props) {
  const { match } = props;
  const { patientId } = match.params;

  const history = useHistory();
  const classes = useStyles();

  const appContext = useContext(AppContext);
  const { patientList, userList } = appContext;

  const [handovers, setHandovers] = useState(null);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [handoverToDelete, setHandoverToDelete] = useState(null);
  const [month, setMonth] = useState(currentMonth);
  const [year, setYear] = useState(currentYear);
  const [user, setUser] = useState(0);
  const [patient, setPatient] = useState(patientId || 0);
  const availableUsers = [
    {
      id: 0,
      label: "Tutti gli operatori",
    },
    ...userList.map((u) => ({
      id: u.id,
      label: `${u.first_name} ${u.last_name}`,
    })),
  ];
  const availablePatients = [
    {
      id: 0,
      label: "Tutti gli utenti",
    },
    ...patientList.map((p) => ({
      id: p.id,
      label: `${p.first_name} ${p.last_name}`,
    })),
  ];

  useEffect(() => {
    const monthValue = month === "all" ? null : month;
    const yearValue = year === "all" ? null : year;
    HandoverService.all({
      patient: patient || null,
      user: user || null,
      month: monthValue,
      year: yearValue,
    }).then((data) => {
      setHandovers(data);
    });
  }, [month, year, patient, user, patientId]);

  const openDeleteDialog = (id) => {
    setHandoverToDelete(id);
    setShowDeleteDialog(true);
  };

  const closeDeleteDialog = () => {
    setHandoverToDelete(null);
    setShowDeleteDialog(false);
  };

  const goToEditPage = (id) => {
    const url = patientId
      ? `/app/patients/${patientId}/handovers/${id}`
      : `/app/handovers/${id}`;
    history.push(url);
  };

  const handleDeleteHandover = () => {
    HandoverService.delete(handoverToDelete)
      .then(() => {
        removeHandoverFromList(handoverToDelete);
        closeDeleteDialog();
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const removeHandoverFromList = (id) => {
    const index = handovers.findIndex((r) => r.id === id);
    let newList = [...handovers];
    newList.splice(index, 1);
    setHandovers(newList);
  };

  return (
    <>
      <Typography variant="h6">Storico consegne</Typography>
      <HandoverFilter
        month={month}
        setMonth={setMonth}
        year={year}
        setYear={setYear}
        user={user}
        setUser={setUser}
        patient={patient}
        setPatient={setPatient}
        availableYears={availableYears}
        availableMonths={monthOptions}
        availableUsers={availableUsers}
        availablePatients={availablePatients}
      />
      {!handovers && <LinearProgress />}
      {handovers && handovers.length === 0 && (
        <Alert severity="warning">Nessuna consegna trovata</Alert>
      )}
      {handovers && handovers.length > 0 && (
        <List>
          {handovers.map((handover) => {
            const { id, start_time, end_time, user, patient, note } = handover;
            const startDateStr = formattedDate(start_time, "EEEEEE dd/MM/yyyy");
            const startTimeStr = formattedDate(start_time, "HH:mm:ss");
            const endTimeStr = formattedDate(end_time, "HH:mm:ss");

            return (
              <span key={id}>
                <ListItem alignItems="flex-start">
                  <ListItemText
                    primary={`${startDateStr} ${startTimeStr} - ${endTimeStr}`}
                    secondary={
                      <>
                        <Typography
                          component="span"
                          variant="body2"
                          className={classes.inline}
                          color="textPrimary"
                        >
                          <strong>
                            {patient.first_name} {patient.last_name}
                          </strong>{" "}
                          (Operatore: {user.first_name} {user.last_name})
                        </Typography>
                      </>
                    }
                  />
                  <div className={classes.textRight}>
                    <div>
                      {note && (
                        <PopupState
                          variant="popover"
                          popupId="demo-popup-popover"
                        >
                          {(popupState) => (
                            <span>
                              <IconButton
                                edge="end"
                                aria-label="Note"
                                {...bindTrigger(popupState)}
                              >
                                <NotesIcon />
                              </IconButton>
                              <Popover {...bindPopover(popupState)}>
                                <Box p={2}>
                                  <Typography>{note}</Typography>
                                </Box>
                              </Popover>
                            </span>
                          )}
                        </PopupState>
                      )}
                      <IconButton
                        edge="end"
                        aria-label="Modifica"
                        onClick={() => {
                          goToEditPage(id);
                        }}
                      >
                        <EditIcon />
                      </IconButton>
                      <IconButton
                        edge="end"
                        aria-label="Elimina"
                        onClick={() => {
                          openDeleteDialog(id);
                        }}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </div>
                  </div>
                </ListItem>
                <Divider variant="inset" component="li" />
              </span>
            );
          })}
        </List>
      )}
      <Dialog open={showDeleteDialog} onClose={closeDeleteDialog}>
        <DialogTitle>
          Sei sicuro di voler cancellare questa consegna?
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Una volta eliminata non sarà più possibile recuperarla
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteHandover} color="primary">
            Conferma
          </Button>
          <Button onClick={closeDeleteDialog} color="primary" autoFocus>
            Annulla
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default HandoverList;
